.App {
  text-align: center;
}

.main {
  width: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
}

.App-logo {
  height: 100vh;
  pointer-events: none;
}

.centered-txt {
  position: absolute;
  color: white;
  font-size: 50px;
  font-weight: bold;
  top: 20%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.App-logo-d {
  height: 100%;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
